/**
 * User profile actions
 */

import isEmptyObject from 'is-empty-object'
import Router from 'next/router'

import { getCartId } from '@/redux/cart/selectors'
import { track } from '@helpers/analytics'
import { defaultCallback } from '@helpers/callbacks'
import { locationHasMenu } from '@helpers/location'
import api from 'api'
import errorHandler from 'error-handler'
import promisify from 'helpers/pify'
import ROUTES from 'helpers/routes'
import { handleAddressSubmissionChange, handleUpdateAddress, locationFetcher } from 'redux/action-wrappers/location'
import { logout } from 'redux/app/actions'
import { fetchCart } from 'redux/cart/actions'
import { setCookie } from 'redux/cookies/actions'
import {
  addressLoaded,
  addressLoading,
  mmidImageLoaded,
  resetPasswordLoaded,
  resetPasswordLoading,
  stateIDImageLoaded,
  userProfileLoaded,
  userProfileLoading,
  userStatusLoaded,
  userStatusLoading
} from 'redux/loading/actions'
import { clearPotentialAddress } from 'redux/location/actions'
import * as userActions from 'redux/user/actions'
import verificationActionTypes from 'redux/verification/actionTypes'

import t from './actionTypes'

const noop = () => {}

/**
 * Request the API to fetch a user profile.
 * This should cleanup the previous user profile (if any)
 */
function requestUserProfile() {
  return (dispatch) => {
    dispatch(userProfileLoading())
    dispatch({
      type: t.REQUEST_USER_PROFILE
    })
  }
}

/**
 * A user profile was received successfully
 */
export function receiveUserProfile(profile) {
  return (dispatch) => {
    dispatch({
      type: t.RECEIVE_USER_PROFILE,
      profile
    })

    dispatch(userProfileLoaded())

    // redirect to any active order
    if (profile.status.deliveryOrderId) {
      Router.push(`/orders/${profile.status.deliveryOrderId}`)
    }
  }
}

export function setBasicVerification(verificationStatus) {
  return {
    type: t.UPDATE_BASIC_VERIFICATION,
    payload: verificationStatus
  }
}

/**
 * A user status object was received successfully
 */
function receiveUserStatus(res) {
  return (dispatch) => {
    if (res.details) {
      const basicVerification = res.details.basic.verificationStatus
      const patientVerification = res.details.patient.verificationStatus
      dispatch({ type: t.UPDATE_BASIC_VERIFICATION, payload: basicVerification })
      dispatch({ type: t.UPDATE_PATIENT_VERIFICATION, patientVerification })
    }

    const userVerification = res.verificationStatus
    dispatch({ type: t.UPDATE_USER_VERIFICATION, userVerification })
  }
}

/*
 * set user zipcode as initial location after login
 */
function handleProfileLocation(address) {
  return (dispatch) => {
    const hasMenu = locationHasMenu(address)
    if (hasMenu) {
      dispatch(handleAddressSubmissionChange(address))
    } else if (!address.depots) {
      dispatch(clearPotentialAddress())
    }
  }
}

/**
 * Fetch the user profile using the API
 * we're using a callback so that we can use @bendrucker's polling library - https://www.npmjs.com/package/pole.
 * we are no longer calling this via polling as of 10/16/19
 * Set shouldRedirectToLogin arg to false to make fetching the user profile NOT shouldRedirectToLogin to the login page
 */

const apiFetchUserProfile = promisify(api.getUserProfile)
const apiFetchPlace = promisify(api.places)

export function fetchUserProfile(id, callback = defaultCallback, shouldRedirectToLogin = true) {
  return (dispatch, getState) => {
    const state = getState()
    // if we don't have an ID, lets grab it from the store
    const {
      app,
      location: { activeLocation },
      user: { userId, xAuthToken }
    } = state
    const USER_ID = id || userId

    // only let the API call take place when both id and xAuthToken are present
    if (!USER_ID || !xAuthToken) {
      if (shouldRedirectToLogin && app.hydrated) {
        dispatch(userActions.removeUser())
        Router.replace(ROUTES.LOGIN)
      }
      // we should always return if we don't have a USER_ID or xAuthToken
      return callback()
    }

    dispatch(requestUserProfile())
    apiFetchUserProfile({ id: USER_ID })
      .then(async (res) => {
        // this is a convenience to place the user in their 'home' menu
        // 'home' menu is associated with the zipCode that they signed up with
        if (isEmptyObject(activeLocation) && res.basic.zipCode) {
          dispatch(addressLoading())

          apiFetchPlace({ query: res.basic.zipCode, zipCode: true })
            .then(async (res) => {
              const locationId = res[0].id
              const address = await locationFetcher(locationId)
              await dispatch(handleProfileLocation(address))
              dispatch(addressLoaded())
              const cartId = getCartId(state)

              // create a cart once we have a location
              if (!cartId && address.id) {
                dispatch(fetchCart(false, () => {}, address.id, true))
              }
            })
            .catch((err) => {
              return errorHandler(new Error(err.message))
            })
        }

        dispatch(
          userActions.setUserBasicAndCookie(
            res.basic.userId,
            res.basic.email,
            res.basic.firstName,
            res.basic.lastName,
            res.basic.zipCode,
            res.basic.mobilePhone,
            res.basic.expirationDate
          )
        )
        dispatch(receiveUserProfile(res))

        return callback(null, res)
      })
      .catch((err) => {
        if (shouldRedirectToLogin && err.statusCode === 401) {
          Router.push(ROUTES.LOGIN)
        }
        errorHandler(new Error(err.message))
        return callback(err)
      })
  }
}

/**
 * We use this to poll the user verification, medical status' in profile
 * Since we use this for polling, we need to allow toggling loading actions (indicateLoading) so we don't flap loading indicators when polling
 */
export function fetchStatus(callback = defaultCallback, indicateLoading = false) {
  return (dispatch, getState) => {
    const state = getState()
    const id = state.user.userId
    const token = state.user.xAuthToken
    if (!id || !token) {
      return callback()
    }

    indicateLoading && dispatch(userStatusLoading())
    // we poll getUserProfile so we can monitor the verification status.
    // getUserStatus only returns an aggregate verification status so
    // we can't actually tell which verification status changed (basic or medical)
    api.getUserStatus({ id }, (err, res) => {
      indicateLoading && dispatch(userStatusLoaded())

      if (err) return dispatch(handleUserEndpointError(err))
      if (res) {
        dispatch(savePhoneVerification(res.isMobilePhoneVerified))
        // let's not call res.details if it isn't defined
        dispatch(receiveUserStatus(res))
      }

      return callback(null, res)
    })
  }
}

export function fetchUserExtra() {
  return (dispatch, getState) => {
    const {
      user: { userId, xAuthToken }
    } = getState()

    if (!userId || !xAuthToken) {
      return
    }

    api.getUserExtra({ id: userId }, (err, res) => {
      if (err) return dispatch(handleUserEndpointError(err))
      if (res) return dispatch(setProfileExtra(res))
    })
  }
}

export function savePhoneVerification(isMobilePhoneVerified) {
  return {
    type: t.SAVE_PHONE_VERIFICATION,
    payload: isMobilePhoneVerified
  }
}

export function updateBasicInfo(params) {
  return (dispatch, getState) => {
    dispatch(userProfileLoading())
    const basic = getState().profile.basic
    const oldPhone = basic.mobilePhone

    let body = {
      ...basic, // bring in everything in from basic, and overwrite the following values
      email: params.email || basic.email,
      firstName: params.firstName || basic.firstName,
      lastName: params.lastName || basic.lastName,
      mobilePhone: params.mobilePhone || oldPhone
    }

    if (oldPhone !== params.mobilePhone) {
      body = Object.assign({}, body, {
        isMobilePhoneVerified: false // we want to reset isMobilePhoneVerified b/c the phone number has changed
      })
    }

    dispatch(writeBasicInfo(body))
  }
}

export function updateStateID(photo) {
  return (dispatch, getState) => {
    const state = getState()
    const { basic } = state.profile

    dispatch(userProfileLoading())

    const profile = {
      birthDate: basic.birthDate,
      email: basic.email,
      firstName: basic.firstName,
      lastName: basic.lastName,
      mobilePhone: basic.mobilePhone,
      contentId: photo.id,
      userId: basic.userId
    }

    dispatch(writeBasicInfo(profile))
  }
}

function writeBasicInfo(profile) {
  return (dispatch) => {
    api.writeBasicUserInfo(profile, (err, res) => {
      dispatch(userProfileLoaded())
      dispatch(stateIDImageLoaded())
      if (err) {
        if (err.message) dispatch(setProfileError(err.message))
        return errorHandler(new Error(err.message))
      }

      dispatch(fetchUserProfile())
    })
  }
}

export function setProfileError(error) {
  return {
    type: t.SET_PROFILE_ERROR,
    error
  }
}

export function updateMMID(photo) {
  return (dispatch, getState) => {
    const {
      user: { userId }
    } = getState()

    const body = {
      contentId: photo.id,
      // The api requires to pass a type of recommendation. Passing 2 for Letter of Recommendation as default. Hopefully the API will soon mark this field as optional.
      eligibilityProofType: 2,
      userId
    }

    dispatch(userProfileLoading())

    api.verifyRecommendation(body, (err, res) => {
      dispatch(mmidImageLoaded())
      dispatch(userProfileLoaded())
      if (err) {
        dispatch({
          type: verificationActionTypes.RECEIVE_UPLOAD_RECOMMENDATION_ERROR,
          err: err.message
        })
        return errorHandler(new Error(err.message))
      }

      dispatch(fetchUserProfile())
    })
  }
}

/**
 * Set the contentId in profile.basic.contentId
 * This represents the identification photo
 */
export function setIdentificationPhoto(id) {
  return {
    type: t.SET_IDENTIFICATION_PHOTO,
    id
  }
}

/**
 * Set the contentId in profile.patient.contentId
 * This represents the recommendation photo
 */
export function setRecommendationPhoto(id) {
  return {
    type: t.SET_RECOMMENDATION_PHOTO,
    id
  }
}

// this is used for resetting a password (from reset email or sms)
export function newPassword(newPassword, token) {
  return (dispatch, getState) => {
    dispatch(resetPasswordLoading())

    const resetPassword = {
      newPassword: newPassword,
      verifyPassword: newPassword,
      token: token
    }

    api.resetPassword(resetPassword, (err, res) => {
      if (err) {
        dispatch(setChangePassword({ err: err.message }))
        dispatch(resetPasswordLoaded())
        return errorHandler(new Error(err.message))
      }

      // if we get back an id, email and token, let's set those in the store
      if (res.id && res.email && res.xAuthToken) {
        dispatch(
          userActions.setUserBasicAndCookie(
            res.id,
            res.email,
            res.firstName,
            res.lastName,
            res.zipCode,
            res.mobilePhone
          )
        )
        dispatch(userActions.setUserTokenAndCookie(res.xAuthToken))
      }

      dispatch(setCookie({ juiced: false }))

      track('Password.Reset', {
        userId: res.id,
        email: res.email
      })

      dispatch(setChangePassword(true))
      dispatch(resetPasswordDone())
      dispatch(resetPasswordLoaded())
      Router.push(ROUTES.MENU)
    })
  }
}

// this is used for changing a password (not from reset)
export function changePassword() {
  return (dispatch, getState) => {
    const state = getState()
    const pwStore = state.profile.password

    const resetPassword = {
      oldPassword: pwStore.oldPassword,
      newPassword: pwStore.newPassword,
      verifyPassword: pwStore.newPassword
    }

    dispatch(resetPasswordLoading())
    api.resetPassword(resetPassword, (err, res) => {
      dispatch(resetPasswordLoaded())

      if (err) {
        dispatch(setChangePassword({ err: err.message }))
        return errorHandler(new Error(err.message))
      }

      dispatch(setChangePassword(true))
      Router.push(ROUTES.MENU)
    })
  }
}

export function resetPasswordErrors() {
  return {
    type: t.RESET_PASSWORD_ERRORS
  }
}

/**
 * this sets a value in the auth store to true letting /change-password know to display the reset password interface
 */
export function needsPwReset(token) {
  return {
    type: t.NEEDS_PW_RESET,
    token
  }
}

/**
 * this is called on the response of a changePassword or resetPassword reseting the {auth.password} object
 */
export function resetPasswordDone() {
  return {
    type: t.PW_RESET
  }
}

export function setNewPassword(newPassword) {
  return {
    type: t.SET_NEW_PASSWORD,
    newPassword
  }
}

export function setOldPassword(value) {
  return {
    type: t.SET_OLD_PASSWORD,
    oldPassword: value
  }
}

export function setVerifyPassword(value) {
  return {
    type: t.SET_VERIFY_PASSWORD,
    verifyPassword: value
  }
}

// ssoUser hits the sso endpoint, and turns an auth token into a user profile
export function ssoUser(redirect, placeId) {
  return (dispatch, getState) => {
    const {
      user: { xAuthToken }
    } = getState()

    api.sso({ xAuthToken }, (err, res) => {
      if (err) return errorHandler(new Error(err.message))
      dispatch(userActions.setUserTokenAndCookie(res.xAuthToken))
      dispatch(
        userActions.setUserBasicAndCookie(res.id, res.email, res.firstName, res.lastName, res.zipCode, res.mobilePhone)
      )
      dispatch(
        fetchUserProfile(
          res.id,
          () => {
            // Since fetchUserProfile updates the user's location to signup zip, we need to make sure handleUpdateAddress runs after
            if (placeId) {
              // Also need to check that a placeId was passed as mobile doesn't currently pass one
              dispatch(handleUpdateAddress(placeId))
            }
          },
          false
        )
      )

      if (redirect) Router.replace(redirect)
    })
  }
}

export function acceptCollectiveAgreement() {
  return (dispatch, getState) => {
    api.signCollectiveAgreement((err, res) => {
      if (err) return errorHandler(new Error(err.message))

      dispatch(setCollectiveAgreement())
    })
  }
}

function setCollectiveAgreement() {
  return {
    type: t.SET_COLLECTIVE_AGREEMENT
  }
}

export function resetProfile() {
  return {
    type: t.RESET_PROFILE
  }
}

export function setChangePassword(changedPassword) {
  if (changedPassword.err) {
    const { err } = changedPassword
    if (err.toLowerCase().trim() === 'invalid password') {
      changedPassword.err = 'Please choose a different password'
    }
  }

  return {
    type: t.SET_CHANGE_PASSWORD,
    changedPassword
  }
}

function setProfileExtra(extra) {
  return {
    type: t.SET_PROFILE_EXTRA,
    extra
  }
}

export function handleUserEndpointError(err) {
  return (dispatch) => {
    if (err.statusCode === 401) {
      return dispatch(logout())
    } else {
      return errorHandler(new Error(err.message))
    }
  }
}

export function requestAccountDeleteConfirm(callback = noop) {
  return (dispatch, getState) => {
    const {
      user: { xAuthToken }
    } = getState()
    if (!xAuthToken) {
      const err = new Error('Cannot request account deletion confirmation without an xAuth token')
      return callback(err, null)
    }
    api.requestAccountDeleteConfirm((err, res) => {
      if (err) {
        errorHandler(new Error(err.message))
        return callback(err, null)
      } else {
        callback(null, res)
      }
    })
  }
}

export function requestAccountDeletion(confirmationId, callback = noop) {
  return (dispatch, getState) => {
    const {
      user: { xAuthToken }
    } = getState()
    if (!xAuthToken) {
      const err = new Error('Cannot request account deletion without an xAuth token')
      return callback(err, null)
    }
    if (!confirmationId || confirmationId === 'confirm') {
      const err = new Error('cannot request account deletion without a confirmation ID')
      errorHandler(err)
      return callback(err, null)
    }
    api.requestAccountDeletionWithToken({ confirmationId }, (err, res) => {
      if (err) {
        errorHandler(new Error(err.message))
        return callback(err, null)
      } else {
        callback(null, res)
      }
    })
  }
}

const uploadPromise = promisify(api.upload)
export function uploadPrivateFile(formData) {
  return uploadPromise(formData)
}

/// //////////////////////
// Persona related actions
/// //////////////////////

// Set decline reasons so we can parse whether it's bc expired vs underage vs dupe
export function setDeclineReasons(declineReasons) {
  return {
    type: t.SET_DECLINE_REASONS,
    declineReasons
  }
}

// Set the cardType of the Persona scanned ID
export function setIdentificationCardType(cardType) {
  return {
    type: t.SET_IDENTIFICATION_CARDTYPE,
    cardType
  }
}

export function setIdOrigin(isUsa) {
  return {
    type: t.SET_ID_ORIGIN,
    isUsa
  }
}

export function setPersonaVerificationStatus(status) {
  return {
    type: t.SET_PERSONA_VERIFICATION_STATUS,
    status
  }
}
